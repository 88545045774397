const styles = ( theme ) => ( {
  aboutTabs: {
    '& button': {
      background: 'none',
    },
  },
  aboutTabsButton: {
    background: 'none',
    '& > div > div > div + span': {
      background: theme.palette.common.darkBlue,
    },
    '& button': {
      background: '#ffffff !important',
      border: '1px solid #153180 !important',
      borderRadius: '30px',
      margin: 'auto 5px',
      color: theme.palette.common.darkBlue,
      fontSize: '14px !important',
      textTransform: 'none !important',
      opacity: '1',
      '&:hover': {
        border: 'none',
        background: '#ffffff !important',
        color: '#153180 !important',
      },
      '& span': {
        fontFamily: 'Open Sans, sans-serif !important',
        fontWeight: 'bold',
      },
      '&.Mui-selected': {
        background: '#153180 !important', // Change this to your desired selected color
        color: '#ffffff !important', // Text color for selected button
        border: '1px solid #ffffff !important', // Border color for selected button
      },
      padding: '5px 25px !important',
    },
  },
  aboutTabsButtonBox: {
    boxShadow: 'none',
  },
  tabContent: {
    display: 'flex',
    '& figure': {
      margin: '0 0 0 0',
      maxWidth: '250px',
      '& img': {
        width: '100%',
      },
    },
    '& h1, h2, h3, h4, h5, h6': {
      color: theme.palette.common.darkBlue,
    },
    '& h4': {
      fontSize: '1.2rem',
    },
    '& h3': {
      fontSize: '1.3rem',
    },
    marginBottom: '50px',
  },
  tabContentText: {
    maxWidth: 'calc(100% - 250px)',
  },
  tabContentHead: {
    '& h3': {
      color: theme.palette.common.darkBlue,
    },
    '& p.tabBrief': {
      color: theme.palette.common.darkBlue,
      marginBottom: '30px',
    },
  },
  tabContentContainer: {
    marginTop: '20px',
    '& > div': {
      paddingLeft: '0',
      paddingRight: '0',
    },
  },
  aboutTabsMain: {
    display: 'block',
    '& header': {
      background: 'transparent',
    },
    [theme.breakpoints.down( 'sm' )]: {
      display: 'none',
    },
  },
} );

export default styles;
